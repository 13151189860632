import React from "react";

const AdminDashboard = () => {

    return (
        <div>
            AdminDashboard
        </div>
    )
}

export default AdminDashboard