import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Polyline } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { fetchPosition, fetchTrail, fetchAvailableUsers  } from "./../firebase";
import { useParams } from 'react-router-dom';


// Remplacer l'icône par défaut de Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
});


const MapPage = () => {
  const [userPosition, setUserPosition] = useState(null);
  const [trailCoordinates, setTrailCoordinates] = useState([]);
  const [showMarker, setShowMarker] = useState(false);
  const [availableUsers, setAvailableUsers] = useState([]); // Déclarez l'état pour les utilisateurs disponibles
  const [selectedUsers, setSelectedUsers] = useState([]); // Déclarez l'état pour les utilisateurs sélectionnés
  const mapRef = useRef();
  const { userId } = useParams();
  

  // Charger la liste des utilisateurs disponibles
  useEffect(() => {
    fetchAvailableUsers().then((users) => {
      setAvailableUsers(users);
    });
  }, []);

  useEffect(() => {
    if (userId) {
      fetchPosition(userId, (position) => {
        if (position) {
          setUserPosition({ lat: position.latitude, lng: position.longitude });
          setShowMarker(true);
        } else {
          setUserPosition({ lat: 48.858844, lng: 2.294351 }); // Paris
          setShowMarker(false);
          console.log("position par défaut")
        }
      });

      fetchTrail(userId, (trailData) => {
        if (trailData) {
          setTrailCoordinates(trailData);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (userPosition && mapRef.current) {
      mapRef.current.setView(userPosition, 13);
    }
  }, [userPosition]);

  // Gérer la sélection des utilisateurs
  const handleUserSelect = (event) => {
    const selectedUserId = event.target.value;
    if (!selectedUsers.includes(selectedUserId)) {
      setSelectedUsers([...selectedUsers, selectedUserId]); // Ajouter un nouvel utilisateur à suivre
    }
  };

  return (
    <div style={{ height: "100vh" }}>
      <div style={{ padding: "10px" }}>
        <label htmlFor="userSelect">Sélectionner un coureur :</label>
        <select id="userSelect" onChange={handleUserSelect} style={{ marginLeft: "10px" }}>
          <option value="">-- Choisir un coureur --</option>
          {availableUsers.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name}
            </option>
          ))}
        </select>
      </div>

      <MapContainer
        ref={mapRef}
        center={userPosition || { lat: 48.858844, lng: 2.294351 }}
        zoom={13}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {trailCoordinates.length > 0 && <Polyline positions={trailCoordinates} color="blue" />}
        {showMarker && userPosition && <Marker position={userPosition} />}
      </MapContainer>
    </div>
  );
};

export default MapPage;