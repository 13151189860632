// src/routes.js
import React from "react";
import OrganisateurForm from "./pages/OrganisateurForm";
import MapPage from "./pages/MapPages";
import HomePage from "./pages/HomePage";
import AdminDashboard from "./pages/AdminDashboard";

const routes = [
    { path: "/", element: <HomePage /> },
    { path : "/admin", element : <AdminDashboard/>},
    { path: "/organisation", element: <OrganisateurForm /> },
    { path: "/user/:userId", element: <MapPage /> }
];

export default routes;
