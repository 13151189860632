import React, { useState } from "react";
import { getDatabase, ref, push } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes } from "firebase/storage";

import "./OrganisateurForm.css";

const OrganisateurForm = () => {
  const [email, setEmail] = useState("");
  const [trailName, setTrailName] = useState("");
  const [gpxFile, setGpxFile] = useState(null);
  const [trailCode, setTrailCode] = useState(""); // Champ pour le code personnalisé
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !trailName || !gpxFile || !trailCode) {
      setStatus("Veuillez remplir tous les champs avant de soumettre.");
      return;
    }

    setIsLoading(true);

    try {
      const db = getDatabase();
      const storage = getStorage();

      // Upload du fichier GPX dans Firebase Storage
      const storagePath = `trail_submissions/${trailCode}.gpx`;
      const fileRef = storageRef(storage, storagePath);
      await uploadBytes(fileRef, gpxFile);

      // Sauvegarde des métadonnées dans une table provisoire
      const submissionsRef = ref(db, "trail_submissions");
      await push(submissionsRef, {
        email,
        trailName,
        gpxPath: storagePath,
        code: trailCode,
        status: "pending", // Status initial : en attente de validation
        createdAt: new Date().toISOString(),
      });

      setStatus(
        `Votre soumission a été envoyée avec succès ! Nous examinerons votre demande sous peu.`
      );
    } catch (error) {
      console.error("Erreur lors de la soumission du trail :", error);
      setStatus("Une erreur s'est produite. Veuillez réessayer.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="form-container">
      <h2>Soumettre un Trail</h2>
      <form onSubmit={handleSubmit} className="organisateur-form">
        <div className="input-group">
          <label>Email de l'organisateur :</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="exemple@domaine.com"
          />
        </div>
        <div className="input-group">
          <label>Nom du Trail :</label>
          <input
            type="text"
            value={trailName}
            onChange={(e) => setTrailName(e.target.value)}
            required
            placeholder="Nom du Trail"
          />
        </div>
        <div className="input-group">
          <label>Fichier GPX :</label>
          <input
            type="file"
            accept=".gpx"
            onChange={(e) => setGpxFile(e.target.files[0])}
            required
          />
        </div>
        <div className="input-group">
          <label>Code du Trail :</label>
          <input
            type="text"
            value={trailCode}
            onChange={(e) => setTrailCode(e.target.value)}
            required
            placeholder="NomDuTrail_ANNEE_DISTANCE"
          />
          <small>Exemple : UTMB_Alsace_2024_50k</small>
        </div>
        <button type="submit" className="submit-button" disabled={isLoading}>
          {isLoading ? "Soumission en cours..." : "Soumettre le Trail"}
        </button>
      </form>
      {status && <p className="status-message">{status}</p>}
    </div>
  );
};

export default OrganisateurForm;
