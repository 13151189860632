// src/pages/Home.js
import React from "react";
import { Link } from "react-router-dom"; // Permet de naviguer sans recharger la page

const HomePage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>Allez Mon Grand</h1>
        <p style={styles.subtitle}>Partagez votre aventure, soyez connecté en temps réel !</p>
      </div>

      <div style={styles.content}>
        <p style={styles.text}>
          Vous êtes traileur et souhaitez que vos proches vous encouragent sur votre parcours ? 
          <strong> Ne les laissez pas attendre dans le froid pendant des heures !</strong>
          <br />
          Avec <strong>Allez Mon Grand</strong>, vous pouvez facilement partager votre position en temps réel et permettre à vos proches de suivre votre aventure sans attendre. Que vous soyez sur un sentier en pleine nature ou lors d'une course, votre famille et vos amis resteront connectés à chaque étape.
        </p>

        <div style={styles.featuresContainer}>
          <div style={styles.feature}>
            <h3 style={styles.featureTitle}>Suivi en temps réel</h3>
            <p style={styles.featureText}>Partagez votre position à tout moment pendant votre trail.</p>
          </div>
          <div style={styles.feature}>
            <h3 style={styles.featureTitle}>Sélection des tracés</h3>
            <p style={styles.featureText}>Choisissez un parcours, et faites-le suivre par vos proches.</p>
          </div>
          <div style={styles.feature}>
            <h3 style={styles.featureTitle}>Pour les organisateurs</h3>
            <p style={styles.featureText}>
              Offrez à tous les participants et leurs proches un suivi géolocalisé durant l'événement.
            </p>
          </div>
        </div>

        <div style={styles.buttonContainer}>
          <Link to="/organisation" style={styles.button}>
            Créer un Trail
          </Link>
          <Link to="/user/12345" style={styles.button}>
            Voir un Trail
          </Link>
        </div>
      </div>

      <div style={styles.footer}>
        <p style={styles.footerText}>Rejoignez-nous et laissez vos proches vivre votre aventure avec vous !</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
    padding: "20px",
    background: "#f4f4f9",
  },
  header: {
    marginBottom: "30px",
  },
  title: {
    fontSize: "3rem",
    fontWeight: "bold",
    color: "#333",
  },
  subtitle: {
    fontSize: "1.5rem",
    color: "#666",
  },
  content: {
    maxWidth: "800px",
    margin: "0 auto",
  },
  text: {
    fontSize: "1.2rem",
    lineHeight: "1.6",
    marginBottom: "40px",
    color: "#444",
  },
  featuresContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "40px",
  },
  feature: {
    width: "30%",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    background: "#fff",
    transition: "transform 0.3s",
  },
  featureTitle: {
    fontSize: "1.4rem",
    color: "#333",
    marginBottom: "10px",
  },
  featureText: {
    fontSize: "1rem",
    color: "#555",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    marginTop: "30px",
  },
  button: {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "12px 24px",
    textDecoration: "none",
    borderRadius: "5px",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "center",
    transition: "background-color 0.3s, transform 0.2s",
  },
  buttonHover: {
    backgroundColor: "#45a049",
    transform: "scale(1.05)",
  },
  footer: {
    marginTop: "50px",
    paddingTop: "20px",
    borderTop: "1px solid #ddd",
  },
  footerText: {
    fontSize: "1rem",
    color: "#777",
    marginTop: "10px",
  },
};

export default HomePage;
